<script setup lang="ts">
import IconClose from '@/assets/icons/close.svg';
import IconMenu from '@/assets/icons/menu.svg';
import IconRight from '@/assets/icons/right.svg';
import { ref, computed } from 'vue';
import { Notify, Popup, Icon } from 'vant';
import 'vant/es/popup/style/less';
import { useRouter } from 'vue-router';
// import useMetaMaskWallet from '@/composables/useMetaMaskWallet';
import useUserStore from '@/store/user';
import { initWeb3 } from '@/utils/request';
import { DAL } from 'firework-bll';
import { getToken } from '@/utils/auth';
import { env } from '@firework/env';
// import { getToken } from '@/utils/auth';

const userStore = useUserStore();

initWeb3();

const router = useRouter();
// const isLoginPage = computed(() => {
//   return unref(router.currentRoute).name === 'login';
// });

const showMenu = ref(false);
const hasToken = getToken();
const account = computed(() => userStore.userInfo.UserName);
// const account = ref<string | null>(
//   localStorage.getItem("Address") || localStorage.getItem("AddressEmail")
// );
const openMenu = () => {
  showMenu.value = true;
};
const closMenu = () => {
  showMenu.value = false;
};

const showWallet = ref(false);
const showAccount = ref(false);
type menuType = {
  label: string;
  path?: string;
  routerName?: string;
  children?: Array<menuType>;
  showSub?: boolean;
};
const addr = ref(localStorage.getItem('Address'));
const menu = ref<Array<menuType>>([
  { label: 'Spark Era Home' },
  // {
  //   label: 'Game',
  //   children: [
  //     { label: 'V1: Interstellar exploration', routerName: 'spaceship-configuration' },
  //     { label: 'V2: Interstellar conquest' },
  //     { label: 'V3: Gearup for war' },
  //   ],
  //   showSub: false,
  // },
  { label: 'Game', routerName: 'game' },
  { label: 'Interstellar Trader', routerName: 'Home' },
  { label: 'Storage', routerName: 'marketplace-storage' },
  { label: 'Loyalty Program', routerName: 'loyalty' },
  { label: 'Referral', routerName: 'invite' },
  { label: 'Reward extraction system' },
  { label: 'Exchange system' },
  { label: 'Whitepaper' },
]);
const onMenuClick = (item: menuType) => {
  // const target = menu[index]
  // console.log(item.showSub)
  item.showSub = !item.showSub;
};
// useMetaMaskWallet().onAccountsChanged();
// useMetaMaskWallet().onChainChanged();

// watch(() =>localStorage.getItem('Address') as string, (newValue) => {
//   if (newValue) {
//     useMetaMaskWallet().onAccountsChanged();
//   }
// });

// function logOut() {
//   location.href = env.ssoUrl + '/logout?redirect=' + location.href;
//   // todo 优化为本系统直接登出
//   // userStore.clearAccount();
//   // closMenu();
//   // location.reload();
//   // const redirect = unref(router.currentRoute).fullPath;
//   // router.push({ name: "login", query: { redirect } });
// }

// function login() {
//   location.href = env.ssoUrl + '/login?redirect=' + location.href;
//   // const redirect = unref(router.currentRoute).fullPath;
//   // router.push({ name: "login", query: { redirect } });
//   // closMenu();
// }

function jump(item = {} as menuType) {
  const { path, routerName } = item;
  if (!path && !routerName) return;
  closMenu();
  if (path) {
    router.push({ path });
  } else if (routerName) {
    router.push({ name: routerName });
  }
}

// async function connect() {
//   // 连接 metamask 钱包
//   // useMetaMaskWallet().connect();
//   try {
//     let addr = await ethereum.request({ method: 'eth_requestAccounts' });
//     localStorage.setItem('Address', addr[0]);

//     addr.value = addr[0];
//     Notify({ message: 'Connected success', type: 'success' });
//   } catch (error) {
//     Notify('Connected to MetaMask is failed');
//   }
// }
function copyAddress() {
  try {
    window.navigator.clipboard.writeText(addr.value as string);
    Notify({ message: 'Copy success', type: 'success' });
  } catch (error) {
    Notify('Copy failed');
  }
}

async function connectToMetaMask() {
  if (ethereum) {
    // (window as any).ethereum = null;
    localStorage.setItem('walletExtension', 'MetaMask');

    const data = await DAL.utils.getWalletEnvironment().request({
      method: 'eth_requestAccounts',
    });
    if (data) {
      localStorage.setItem('Address', data[0]);
      addr.value = data[0];
      showWallet.value = false;
      window.location.reload();
    }
  } else {
    Notify('Please install MetaMask');
  }
}

async function connectToBitKeep() {
  if ((window as any).isBitKeep) {
    // (window as any).ethereum = null;
    localStorage.setItem('walletExtension', 'BitKeep');

    const data = await DAL.utils.getWalletEnvironment().request({
      method: 'eth_requestAccounts',
    });
    if (data) {
      // localStorage.setItem('walletExtension', 'BitKeep');
      localStorage.setItem('Address', data[0]);
      addr.value = data[0];
      showWallet.value = false;
      window.location.reload();
    }
  } else {
    Notify('Please install BitKeep');
  }
}

function logOut() {
  localStorage.clear();
  window.location.reload();
  showWallet.value = false;
}

function accountChange() {
  if (hasToken) {
    showAccount.value = true;
  } else {
    location.href = env.ssoUrl + '/login?redirect=' + location.href;
  }
}

function logOutWithAccount() {
  userStore.clearAccount();
  // closMenu();
  // location.reload();
  // const redirect = unref(router.currentRoute).fullPath;
  // router.push({ name: 'login', query: { redirect } });
  location.href = env.ssoUrl + '/logout?redirect=' + location.href;
}
</script>

<template>
  <div class="header">
    <img class="logo" src="../assets/header/logo.png" alt="" />
    <IconClose v-if="showMenu" @click="closMenu" />
    <IconMenu v-else @click="openMenu" />
  </div>
  <Popup class="menu-popup" v-model:show="showMenu" position="left" :overlay="false">
    <div class="menu-box">
      <div class="menu-group">
        <div
          class="menu-item"
          v-for="(item, index) in menu"
          :key="index"
          @click="jump(item)"
        >
          <div class="menu-item-box" @click="onMenuClick(item)">
            <span class="label"> {{ item.label }} </span>
            <IconRight
              :class="{ open: item.showSub }"
              v-if="item.children && item.children.length"
            />
          </div>
          <transition name="fade">
            <div class="submenu-group" v-if="item.children && item.showSub">
              <div
                class="submenu-item"
                v-for="(submenu, index) in item.children"
                :key="index"
                @click="jump(submenu)"
              >
                <span class="submenu-item-label">
                  {{ submenu.label }}
                </span>
              </div>
            </div>
          </transition>
        </div>
      </div>

      <!-- login btn -->
      <!-- <div class="login-box" v-if="!isLoginPage">
        <div v-if="hasToken">
          <div class="login-account">
            {{ account }}
          </div>
          <div class="connect-btn" @click="logOut">Log Out</div>
        </div>
        <div class="connect-btn" @click="login" v-else>Log in</div>
      </div> -->
      <!-- <p class="ml-10 font-bold" v-if="addr" @click="showWallet = true">
        {{ addr?.substring(0, 6) }}...{{ addr?.substring(addr.length - 6) }}
      </p> -->
      <div class="login-box">
        <div class="connect-btn" @click="showWallet = true">
          {{
            addr
              ? `${addr?.substring(0, 6)}...${addr?.substring(addr.length - 6)}`
              : 'Connect Wallet'
          }}
        </div>
      </div>
      <div class="login-box">
        <div class="connect-btn" @click="accountChange">{{ account || 'Login' }}</div>
      </div>

      <Popup
        v-model:show="showAccount"
        round
        position="bottom"
        :style="{ height: '70%' }"
      >
        <div class="content">
          <div class="wallet-address">
            <div class="user-content">
              <div>
                <p>email account</p>
                <p class="user-info mt-2">
                  {{ account }}

                  <Icon class="ml-2" size="20" name="orders-o" @click="copyAddress" />
                </p>
              </div>
            </div>

            <div class="user-logout">
              <button @click="logOutWithAccount" class="text-2xl">log out</button>
            </div>
          </div>
        </div>
      </Popup>

      <Popup v-model:show="showWallet" round position="bottom" :style="{ height: '70%' }">
        <div class="content">
          <div v-if="!addr">
            <div class="flex items-center mx-8 mt-4">
              <img src="@/assets/header/wallet.png" class="mr-2" alt="" /> Wallet
            </div>
            <!-- wallet tip -->
            <p class="wallet-tip mx-6 mb-10">
              If you have not connected your wallet, you will not be able to trade
            </p>
            <!-- metask Button -->
            <div class="metask-box">
              <div class="metask-btn">
                <button @click="connectToMetaMask">
                  <img src="@/assets/header/metamask.png" width="35" />MetaMask
                </button>
              </div>
            </div>
            <!-- bitkeep Button -->
            <div class="metask-box">
              <div class="bitkeep-btn">
                <button @click="connectToBitKeep">
                  <img src="@/assets/header/bitkeep.png" width="35" />

                  BitKeep
                </button>
              </div>
            </div>
            <!-- WalletConnect -->
            <div class="metask-box">
              <div class="bitkeep-btn">
                <button disabled class="disabled">
                  <img src="@/assets/header/walletContent.png" width="35" />

                  WalletConnect
                </button>
              </div>
            </div>
            <!-- Coinbase Wallet -->
            <div class="metask-box">
              <div class="bitkeep-btn">
                <button disabled class="disabled">
                  <img src="@/assets/header/coinbaseWallet.png" width="35" />

                  Coinbase Wallet
                </button>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="wallet-address">
              <div class="user-content">
                <div>
                  <p>Wallet address</p>
                  <p class="user-info mt-2">
                    {{ addr?.substring(0, 6) }}...
                    {{ addr?.substring(addr.length - 6) }}

                    <Icon class="ml-2" size="20" name="orders-o" @click="copyAddress" />
                  </p>
                </div>
              </div>

              <div class="user-logout">
                <button @click="logOut" class="text-2xl">log out</button>
              </div>
            </div>
          </div>
        </div>
      </Popup>

      <!-- links -->
      <div class="icon-group">
        <img src="../assets/header/icon-1.png" />
        <img src="../assets/header/icon-2.png" />
        <img src="../assets/header/icon-3.png" />
        <img src="../assets/header/icon-4.png" />
      </div>
    </div>
  </Popup>
</template>

<style lang="less" scoped>
.header {
  padding: 0 50px;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #232b3e;

  .logo {
    // 322 × 48
    width: 322px;
    height: 48px;
    object-fit: contain;
  }

  .icon {
    width: 36px;
    height: 36px;
    fill: white;
  }
}

.menu-box {
  // padding: 80px;
  padding: 50px 0;

  .menu-group {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .menu-item {
      .menu-item-box {
        padding: 30px 80px;
        display: flex;
        align-items: center;

        &:hover {
          background-color: darkorange;
        }
      }

      .icon {
        width: 40px;
        height: 40px;
        fill: white;
        transition: all 0.2s;

        &.open {
          transform: rotate(90deg);
        }
      }

      .label {
        font-size: 40px;
        line-height: 40px;
        padding-left: 16px;
        border-left: 2px solid #dedede;
        font-family: ShentoxBold;
      }
    }

    .submenu-item {
      padding: 30px 80px;
      padding-left: 120px;
      display: flex;
      align-items: center;

      &:hover {
        background-color: darkorange;
      }

      .submenu-item-label {
        font-size: 40px;
        line-height: 40px;
        // padding-left: 16px;
        // border-left: 2px solid #DEDEDE;
        font-family: ShentoxBold;
      }
    }
  }

  .login-box {
    margin: 60px 80px;
    // display: flex;
    // flex-direction: column;
    // align-items: stretch;
    .login-account {
      // background: rgba(255, 255, 255, 0.05);
      padding: 20px 40px;
      text-align: center;
    }
    .connect-btn {
      background-color: #4b5dbd;
      font-family: ShentoxBold;
      height: 100px;
      line-height: 100px;
      text-align: center;
    }
  }

  .icon-group {
    margin: 60px 60px;
    display: flex;
    // justify-content: space-between;
    justify-content: center;
    align-items: center;

    img {
      width: 64px;
      height: 64px;
      object-fit: contain;
      margin: auto 20px;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s;
  height: 300px;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  height: 0;
}

.content {
  margin: 20px;
}
.modal-content {
  .wallet {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #151a30;
    color: #86deff;
    font-size: 24px;
    font-family: ShentoxBold;
    img {
      margin-right: 10px;
    }
  }

  .wallet-tip {
    font-size: 22px;
    // font-family: Shentox TRIAL;
    margin: 0 100px;
    font-weight: 400;
    line-height: 24px;
    color: #868d98;
  }
}

.metask-box {
  margin: 0 50px;
  img {
    margin-left: 10px;
    margin-right: 20px;
  }
  button {
    width: 100%;
    height: 100px;
    display: flex;
    // justify-content: space-between;

    align-items: center;
    margin: 10px 0;
    font-size: 18px;
    font-family: ShentoxBold;
    border: 2px solid #425ead;
    background: #3547ac;
    outline: none;
    color: white;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
      border-color: #29305d;
      background: #1b2143;
      filter: grayscale(1);
    }
  }
}

.wallet-address {
  display: flex;
  align-content: space-between;
  flex-flow: wrap;
  height: calc(70vh - 200px);
  margin: 0 50px;

  .user-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 149px;
    margin-top: 30px;
    background: #111522;
    border: 1px solid #262e45;
    opacity: 1;
    border-radius: 16px;

    p {
      text-align: center;
    }
  }

  .user-info {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-family: ShentoxBold;
  }

  .user-logout {
    width: 100%;
  }
  button {
    width: 100%;
    height: 100px;
    background: #3547ac;
    border: 1px solid #4566c3;
    opacity: 1;
    font-family: ShentoxBold;
    color: white;
    font-size: 40px;
    outline: none;
    cursor: pointer;
  }
}
</style>

<style lang="less">
.menu-popup {
  background-color: #0a1121;
  // height: calc(100% - 250px);
  height: calc(100% - 120px);
  width: 100%;
  // height: 100%;
  top: calc(50% + 60px);
  // bottom: 0;

  // &.van-popup--left {
  //   // transform: unset;
  //   transform: translate3d(0, 0, 0);

  // }
}
</style>
