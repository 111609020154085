const development = {
  websiteUrl: "https://test.firework.games",
  mWebsiteUrl: "https://test.firework.games",
  metaverseUrl: "https://meta.firework.games",
  mMetaverseUrl: "https://meta.firework.games",
  marketUrl: "https://test.market.firework.games",
  mMarketUrl: "https://test.m.firework.games",
  gameV1Url: "https://testv1.firework.games",
  mGameV1Url: "https://test.m.firework.games",
  ssoUrl: "//testpassport.firework.games",
  nodeUrl: "https://tregapi.firework.games",
  javaUrl: "https://tmktapi.firework.games",
  unityUrl: "https://www.unbuild.io",
  ntfUrl: "https://www.unbuild.io",
  BlindBoxAddr: "0xb0E68C0CC0E767f88FAac44aa43d768CDc0bE5ff",
  BlindBoxBlackSmithAddr: "0x7F67c447BE6fbA237cbA7594befaF0b1091D43e5",
  BlindBoxOpenerManagerAddr: "0xd41Ad276E7f3c833d60A55799A0dB4C97ae91fe5",
  BOpenerManagerShipAddr: "0xb0E68C0CC0E767f88FAac44aa43d768CDc0bE5ff",
  FireworkCardAddr: "0x771d763Ca8B3848622b30C8CB3d4A444d36a706f",
  BBoxOpenerTyp1Addr: "0x0237Bd071a2676F3821F0896Fc2241d256C2c6Ba",
  GlobalStakingManagerAddr: "0x2A05D633E1805DBf319076376aF7E18894e67979",
  SlotMachineAddr: "0x6a1C7B5983b09623F3B47f237B4e9F420b9234c5",
  PetEggAddr: "0x63C253d17b9873eE1d8Da427915C7515fa5a0dC5",
  PowerCalculatorAddr: "0xB2dc1cAd7dC7a6f15349ED35D7F45318f874ecAf",
  DurationLostCalculatorAddr: "0x65eA02A1B83432C2d06bC602FDdb6ff87093CA6D",
  StarShipRepairAddr: "0x43B2c6b1b3444A10BECb3230F29AA7093B10eec9",
  StarShipAddr: "0xbD893AE8eb937d71F853a65E1c6eCB93B846bc57",
  FleetAddr: "0x6eBa0f27705194F1036813eB6C47Abf8a309845B",
  StarShipSlotOpenerAddr: "0x159a1fFd21ba3c04c0Af0671c35b0F1A0A087299",
  MiningPoolManagerAddr: "0x8Cb7607D12a613324EF3650369b7354043D43C4b",
  RewardAndTaxManagerAddr: "0x6Bfa5E8c89aa132fA864A1D3a6fDf429521ef9f9",
  PSEAddr: "0x574FdFE13b210784DdA48d59f7Be808fcb6f4248",
  PseMigratorAddr: "0x4fF414589FaF8Be96662C2F6a7e9F2b36261803d",
  PSEOnBSCAddr: "0xC324eC88536e08Ac772399cf49EbB641E32e6212",
  PSEClaimShipRewardAddr: "0x7739dE0A0ef9E7964F693f343eE1292a76430a81",
  TestPSEOnBSCBlackSmithAddr: "0x9b94411f37486ED283D8727E97286A0eFaeE9c42",
  EGSEMigratorAddr: "0x6773023882bD4AED8473688Bd5d1ec6d19dec02E",
  EGSEAddrPolygonAddr: "0xc086678D522F6b0EEe51715160e0C4AC56D2aAcc",
  HSEAddr: "0x31E5f8aF86Bd4EAe5947bC5BE37f5B0Deae52f11",
  HSEBlackSmithAddr: "0x9C40D1ba839eBA20c6eEF3b504595f19497c59a2",
  halloweenblacksmithAddr: "0x9C40D1ba839eBA20c6eEF3b504595f19497c59a2",
  testFireAddr: "0x4cCbCEB7AcF13cb78C25C736c6D9A49894C3ee2b",
  testQuarkAddr: "0xCdd4403AF2c2Ecf94B61F5A02D429Ad80959de84",
  NFTTransferAddr: "0xC1d6F6e7526a0166b993Fa1d52A1377F55cF72b9",
  NFTTransfer1155Addr: "0xA86af309A51aC39C73BAa60A9F2d3Cde1aa1d220",
  STAKEAddr: "0xbB0D9d046dEC75b6156f5C1aBa9ADF79056Efb4a",
  DUNGENAddr: "0x97b26793C3562ca3B59A997B85F55bD060C157bb",
  TOKEN_DECIMALS: 18,
  CHAIN_ID: "0x61",
  PSECHAIN_ID: "0x13881",
  AssetManagerAddr: "0x17Ba88B3C24559c3683e997055b4F0903ea60043",
  TOKEN_ERC20_FIRE: "0x0f0Dd5E2c0e0c4A41F8908D73D36B8D142F6745a",
  TOKEN_ERC20_QUARK: "0xCdd4403AF2c2Ecf94B61F5A02D429Ad80959de84",
  TOKEN_ERC20_USDT: "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd",
  TOKEN_ERC20_BNB: "0",
  AWARDAddr: "0xcaeAfE38DC1B6B692cD8Fc15A6eFeA3541e82f41",
  APP_DOWNLOAD_URL_ANDROID: "https://sparkmintest.s3.ap-east-1.amazonaws.com/com.Firework.SparkEra_Debug.apk",
  APP_DOWNLOAD_URL_IOS: "",
  AUTHTOKEN_NAME: "test_sessionId"
};

const test = {
  websiteUrl: "https://test.firework.games",
  mWebsiteUrl: "https://test.firework.games",
  metaverseUrl: "https://meta.firework.games",
  mMetaverseUrl: "https://meta.firework.games",
  marketUrl: "https://test.market.firework.games",
  mMarketUrl: "https://test.m.firework.games",
  gameV1Url: "https://testv1.firework.games",
  mGameV1Url: "https://test.m.firework.games",
  gameV2Url: "https://www.unbuild.io",
  mGameV2Url: "https://www.unbuild.io",
  ssoUrl: "//testpassport.firework.games",
  nodeUrl: "https://tregapi.firework.games",
  javaUrl: "https://tmktapi.firework.games",
  unityUrl: "https://www.unbuild.io",
  ntfUrl: "https://www.unbuild.io",
  BlindBoxAddr: "0xb0E68C0CC0E767f88FAac44aa43d768CDc0bE5ff",
  BlindBoxBlackSmithAddr: "0x7F67c447BE6fbA237cbA7594befaF0b1091D43e5",
  BlindBoxOpenerManagerAddr: "0xd41Ad276E7f3c833d60A55799A0dB4C97ae91fe5",
  BOpenerManagerShipAddr: "0xb0E68C0CC0E767f88FAac44aa43d768CDc0bE5ff",
  FireworkCardAddr: "0x771d763Ca8B3848622b30C8CB3d4A444d36a706f",
  BBoxOpenerTyp1Addr: "0x0237Bd071a2676F3821F0896Fc2241d256C2c6Ba",
  GlobalStakingManagerAddr: "0x2A05D633E1805DBf319076376aF7E18894e67979",
  SlotMachineAddr: "0x6a1C7B5983b09623F3B47f237B4e9F420b9234c5",
  PetEggAddr: "0x63C253d17b9873eE1d8Da427915C7515fa5a0dC5",
  PowerCalculatorAddr: "0xB2dc1cAd7dC7a6f15349ED35D7F45318f874ecAf",
  DurationLostCalculatorAddr: "0x65eA02A1B83432C2d06bC602FDdb6ff87093CA6D",
  StarShipRepairAddr: "0x43B2c6b1b3444A10BECb3230F29AA7093B10eec9",
  StarShipAddr: "0xbD893AE8eb937d71F853a65E1c6eCB93B846bc57",
  FleetAddr: "0x6eBa0f27705194F1036813eB6C47Abf8a309845B",
  StarShipSlotOpenerAddr: "0x159a1fFd21ba3c04c0Af0671c35b0F1A0A087299",
  MiningPoolManagerAddr: "0x8Cb7607D12a613324EF3650369b7354043D43C4b",
  RewardAndTaxManagerAddr: "0x6Bfa5E8c89aa132fA864A1D3a6fDf429521ef9f9",
  PSEAddr: "0x574FdFE13b210784DdA48d59f7Be808fcb6f4248",
  PseMigratorAddr: "0x4fF414589FaF8Be96662C2F6a7e9F2b36261803d",
  PSEOnBSCAddr: "0xC324eC88536e08Ac772399cf49EbB641E32e6212",
  PSEClaimShipRewardAddr: "0x7739dE0A0ef9E7964F693f343eE1292a76430a81",
  TestPSEOnBSCBlackSmithAddr: "0x9b94411f37486ED283D8727E97286A0eFaeE9c42",
  EGSEMigratorAddr: "0x6773023882bD4AED8473688Bd5d1ec6d19dec02E",
  EGSEAddrPolygonAddr: "0xc086678D522F6b0EEe51715160e0C4AC56D2aAcc",
  HSEAddr: "0x31E5f8aF86Bd4EAe5947bC5BE37f5B0Deae52f11",
  HSEBlackSmithAddr: "0x9C40D1ba839eBA20c6eEF3b504595f19497c59a2",
  halloweenblacksmithAddr: "0x9C40D1ba839eBA20c6eEF3b504595f19497c59a2",
  testFireAddr: "0x4cCbCEB7AcF13cb78C25C736c6D9A49894C3ee2b",
  testQuarkAddr: "0xCdd4403AF2c2Ecf94B61F5A02D429Ad80959de84",
  NFTTransferAddr: "0xC1d6F6e7526a0166b993Fa1d52A1377F55cF72b9",
  STAKEAddr: "0xbB0D9d046dEC75b6156f5C1aBa9ADF79056Efb4a",
  DUNGENAddr: "0x97b26793C3562ca3B59A997B85F55bD060C157bb",
  TOKEN_DECIMALS: 18,
  CHAIN_ID: "0x61",
  PSECHAIN_ID: "0x13881",
  AssetManagerAddr: "0x17Ba88B3C24559c3683e997055b4F0903ea60043",
  TOKEN_ERC20_FIRE: "0x0f0Dd5E2c0e0c4A41F8908D73D36B8D142F6745a",
  TOKEN_ERC20_QUARK: "0xCdd4403AF2c2Ecf94B61F5A02D429Ad80959de84",
  TOKEN_ERC20_USDT: "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd",
  TOKEN_ERC20_BNB: "0",
  AWARDAddr: "0xcaeAfE38DC1B6B692cD8Fc15A6eFeA3541e82f41",
  APP_DOWNLOAD_URL_ANDROID: "https://sparkmintest.s3.ap-east-1.amazonaws.com/com.Firework.SparkEra_Debug.apk",
  APP_DOWNLOAD_URL_IOS: "",
  AUTHTOKEN_NAME: "test_sessionId"
};

const pre = {
  websiteUrl: "https://www.firework.games",
  mWebsiteUrl: "https://www.unbuild.io",
  marketUrl: "https://mkttestnet.firework.games",
  metaverseUrl: "https://meta.firework.games",
  mMetaverseUrl: "https://meta.firework.games",
  mMarketUrl: "https://www.unbuild.io",
  gameV1Url: "https://supply.firework.games",
  mGameV1Url: "https://www.unbuild.io",
  gameV2Url: "https://www.unbuild.io",
  mGameV2Url: "https://www.unbuild.io",
  ssoUrl: "//betapassport.firework.games",
  nodeUrl: "https://regapi.firework.games",
  javaUrl: "https://mktbeta.firework.games",
  unityUrl: "https://www.unbuild.io",
  ntfUrl: "https://www.unbuild.io",
  BlindBoxAddr: "0xb0E68C0CC0E767f88FAac44aa43d768CDc0bE5ff",
  BlindBoxBlackSmithAddr: "0xD46D7A23a8184151F361b0fB5bc337108863F88d",
  BlindBoxOpenerManagerAddr: "0xd41Ad276E7f3c833d60A55799A0dB4C97ae91fe5",
  BOpenerManagerShipAddr: "0xb0E68C0CC0E767f88FAac44aa43d768CDc0bE5ff",
  FireworkCardAddr: "0x771d763Ca8B3848622b30C8CB3d4A444d36a706f",
  BBoxOpenerTyp1Addr: "0x0237Bd071a2676F3821F0896Fc2241d256C2c6Ba",
  GlobalStakingManagerAddr: "0x2A05D633E1805DBf319076376aF7E18894e67979",
  SlotMachineAddr: "0x6a1C7B5983b09623F3B47f237B4e9F420b9234c5",
  PetEggAddr: "0x63C253d17b9873eE1d8Da427915C7515fa5a0dC5",
  PowerCalculatorAddr: "0xB2dc1cAd7dC7a6f15349ED35D7F45318f874ecAf",
  DurationLostCalculatorAddr: "0x65eA02A1B83432C2d06bC602FDdb6ff87093CA6D",
  StarShipRepairAddr: "0x43B2c6b1b3444A10BECb3230F29AA7093B10eec9",
  StarShipAddr: "0xbD893AE8eb937d71F853a65E1c6eCB93B846bc57",
  FleetAddr: "0x6eBa0f27705194F1036813eB6C47Abf8a309845B",
  StarShipSlotOpenerAddr: "0x159a1fFd21ba3c04c0Af0671c35b0F1A0A087299",
  MiningPoolManagerAddr: "0x8Cb7607D12a613324EF3650369b7354043D43C4b",
  RewardAndTaxManagerAddr: "0x6Bfa5E8c89aa132fA864A1D3a6fDf429521ef9f9",
  PSEAddr: "0x574FdFE13b210784DdA48d59f7Be808fcb6f4248",
  PseMigratorAddr: "0x4fF414589FaF8Be96662C2F6a7e9F2b36261803d",
  PSEOnBSCAddr: "0xC324eC88536e08Ac772399cf49EbB641E32e6212",
  PSEClaimShipRewardAddr: "0x7739dE0A0ef9E7964F693f343eE1292a76430a81",
  TestPSEOnBSCBlackSmithAddr: "0x9b94411f37486ED283D8727E97286A0eFaeE9c42",
  EGSEMigratorAddr: "0x6773023882bD4AED8473688Bd5d1ec6d19dec02E",
  EGSEAddrPolygonAddr: "0xc086678D522F6b0EEe51715160e0C4AC56D2aAcc",
  HSEAddr: "0x31E5f8aF86Bd4EAe5947bC5BE37f5B0Deae52f11",
  HSEBlackSmithAddr: "0x9C40D1ba839eBA20c6eEF3b504595f19497c59a2",
  halloweenblacksmithAddr: "0x9C40D1ba839eBA20c6eEF3b504595f19497c59a2",
  testFireAddr: "0x4cCbCEB7AcF13cb78C25C736c6D9A49894C3ee2b",
  testQuarkAddr: "0xCdd4403AF2c2Ecf94B61F5A02D429Ad80959de84",
  NFTTransferAddr: "0xC1d6F6e7526a0166b993Fa1d52A1377F55cF72b9",
  STAKEAddr: "0xbB0D9d046dEC75b6156f5C1aBa9ADF79056Efb4a",
  DUNGENAddr: "0x97b26793C3562ca3B59A997B85F55bD060C157bb",
  TOKEN_DECIMALS: 18,
  CHAIN_ID: "0x61",
  PSECHAIN_ID: "0x13881",
  AssetManagerAddr: "0x17Ba88B3C24559c3683e997055b4F0903ea60043",
  TOKEN_ERC20_FIRE: "0x0f0Dd5E2c0e0c4A41F8908D73D36B8D142F6745a",
  TOKEN_ERC20_QUARK: "0xCdd4403AF2c2Ecf94B61F5A02D429Ad80959de84",
  TOKEN_ERC20_USDT: "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd",
  TOKEN_ERC20_BNB: "0",
  AWARDAddr: "0xcaeAfE38DC1B6B692cD8Fc15A6eFeA3541e82f41",
  APP_DOWNLOAD_URL_ANDROID: "https://download.firework.games/com.Firework.SparkEra_Release.apk",
  APP_DOWNLOAD_URL_IOS: "",
  AUTHTOKEN_NAME: "test_sessionId"
};

const production = {
  websiteUrl: "https://www.firework.games/",
  mWebsiteUrl: "https://firework.games",
  metaverseUrl: "https://meta.firework.games",
  mMetaverseUrl: "https://meta.firework.games",
  marketUrl: "https://market.firework.games",
  mMarketUrl: "https://www.unbuild.io",
  gameV1Url: "https://supply.firework.games",
  mGameV1Url: "https://www.unbuild.io",
  gameV2Url: "https://www.unbuild.io",
  mGameV2Url: "https://www.unbuild.io",
  ssoUrl: "//passport.firework.games",
  nodeUrl: "https://ssoapi.firework.games",
  javaUrl: "https://mktbeta.firework.games",
  unityUrl: "https://mktbeta.firework.games",
  ntfUrl: "https://www.unbuild.io",
  BlindBoxAddr: "0xC597623893C010DD0d4b02FA9cDE1fBDec74F9fb",
  BlindBoxBlackSmithAddr: "0xB840338894bfB3C464d3c3ae84DD51d160020352",
  BlindBoxOpenerManagerAddr: "0xB66beac3a9F62dd55e0f07bB5B00A582cF2c6a23",
  BOpenerManagerShipAddr: "0xC597623893C010DD0d4b02FA9cDE1fBDec74F9fb",
  FireworkCardAddr: "0xe28647ac592E5CC81Eca240DF3923B19b6196182",
  BBoxOpenerTyp1Addr: "0xCBefE08064FE7208d6C7644276470614a22545c0",
  PetEggAddr: "0xA9b1a219141789Ba2a3e6360D50A31C350f1B000",
  PowerCalculatorAddr: "0xD66072f93EDB38616C66fF97832A671CAc51a44b",
  DurationLostCalculatorAddr: "0x53644fCe1432d4B09B552a60599D65240a31F28b",
  StarShipRepairAddr: "0x43B2c6b1b3444A10BECb3230F29AA7093B10eec9",
  StarShipAddr: "0x09eC46465c77602084d53022809A47034fF02D06",
  StarShipSlotOpenerAddr: "0x60CFf1730F7F66f4ef80E36E7560De082abBE6A3",
  RewardAndTaxManagerAddr: "0xFbbD906f5e7E16f340BBE57f56239216eFa96295",
  PSEOnBSCAddr: "0xD46D7A23a8184151F361b0fB5bc337108863F88d",
  PSEClaimShipRewardAddr: "0xc937C63599c6F4509B0B84702cb8C37B97fae579",
  HSEAddr: "0xeC2152E56dB8F392eF3E171BC3eDBd33c0188410",
  NFTTransferAddr: "0x765dCBAe838ABA1d0ca775B408828Ec3cF0a57Fd",
  STAKEAddr: "0xFe992A970664A863E7BBC92Fd75181C8DB7Efcc3",
  TOKEN_DECIMALS: 18,
  CHAIN_ID: "0x38",
  PSECHAIN_ID: "0x13881",
  AssetManagerAddr: "0x04036BA094fC733D83A5391F7eEC849394a258BD",
  TOKEN_ERC20_FIRE: "0x0f0Dd5E2c0e0c4A41F8908D73D36B8D142F6745a",
  TOKEN_ERC20_QUARK: "0xa194F135C79EeC7ABb50aFEd7dA4daD7646E58A0",
  TOKEN_ERC20_USDT: "0x55d398326f99059fF775485246999027B3197955",
  TOKEN_ERC20_BNB: "0",
  AWARDAddr: "0x74eddDb47DecCacc212bC93C7d0B765d588D518a",
  APP_DOWNLOAD_URL_ANDROID: "https://download.firework.games/com.Firework.SparkEra_Release.apk",
  APP_DOWNLOAD_URL_IOS: "",
  SENTRY_DSN_GAME: "https://223c15033b954b7a874e63b8a4c918da@o1324597.ingest.sentry.io/6676011",
  SENTRY_DSN_MOBILE_V1: "https://93f4b21f0f1b40a99085f974f7a31465@o1324597.ingest.sentry.io/6676297",
  SENTRY_DSN_SSO: "https://7e3d8bfb629b43e688efdbf99ad59175@o1324597.ingest.sentry.io/6676295",
  SENTRY_DSN_GAME_V1: "https://b670cfa294c74d53996235904f0d2d3e@o1324597.ingest.sentry.io/6676293",
  AUTHTOKEN_NAME: "sessionId"
};

let env = {};
const FIREWORK_ENV = process.env.FIREWORK_ENV || process.env.NODE_ENV;
switch (FIREWORK_ENV) {
  case "development":
    env = development;
    break;
  case "test":
    env = test;
    break;
  case "pre":
    env = pre;
    break;
  case "production":
    env = production;
}

export { FIREWORK_ENV, env };
