import { env } from '@firework/env';
import axios from 'axios';
import { ethers, utils as utils$2, BigNumber } from 'ethers';
import Cookies from 'cookies-ts';
import { IERC721Contract, IERC721 as IERC721$1, erc721YContract, erc721Y, erc20Contract, erc20, IBlindBoxBlackSmithContract, IBlindBoxBlackSmith, IBlindBoxOpenerManagerContract, IBlindBoxOpenerManager, IBlindBoxOpenerContract, IBlindBoxOpener, IBlindBoxContract, IBlindBox, IFireworkAssetManagerContract, IFireworkAssetManager, ICommissionAwardContract, ICommissionAward, IStakeContract, IStake, NFTTransferContract } from '@firework/web3';
import { GoogleAnalyticsPurchase } from 'firework-analytics';
import { Md5 } from 'ts-md5';

const cardList = [
  {
    BoxSrc: "mybox/box18big.png",
    BoxSrcBig: "mybox/box18big.png",
    qSrc: "qian.png",
    money: [18, 0],
    BTN: "BUY",
    typeBox: 18,
    title: "V3-GALAXY LOOT BOX",
    titleshor: "V3-GALAXY LOOT BOX",
    con1: "* Each player can anly purchase up to 2 within 24 hours.",
    con2: "* Please note: This box does not enjoy the preferential policy of the pass!",
    content: "is an extremely precious loot box with limited supply. In order to let all players know the legendary V3 world in advance, we provide a special box that will only appear in the V3 version of V3-GALAXY LOOT BOX, and surprisingly use QUARK as the only means of payment. This will get everyone excited. In this box, you can get limited spaceships, equipped weapons and even planetary control in advance!",
    dialogTitle: "Card Pack Introduction:",
    card: false,
    card2: false,
    bgc: "bgred.png",
    price: ""
  },
  {
    BoxSrc: "mybox/box5big.png",
    BoxSrcBig: "mybox/box5big.png",
    qSrc: "qian.png",
    money: [5, 0],
    BTN: "BUY",
    typeBox: 5,
    title: "PREMIUM LOOT BOX",
    titleshor: "PREMIUM LOOT BOX",
    content: "is a common loot box that contains NFT hero cards of all materials and rarities. There is a certain probability of dropping gold, silver and other special material cards.",
    dialogTitle: "Card Pack Introduction:",
    card: true,
    card2: true,
    bgc: "bglan1.png",
    price: ""
  },
  {
    BoxSrc: "mybox/box7big.png",
    BoxSrcBig: "mybox/box7big.png",
    qSrc: "qian.png",
    money: [7, 1],
    BTN: "BUY",
    typeBox: 7,
    title: "LEGENDARY LOOT BOX",
    titleshor: "LEGENDARY LOOT BOX",
    con1: "* Each player can anly purchase up to 2 within 24 hours.",
    content: "is an extremely valuable loot boxes with limited supply that contains NFT hero cards of all materials and rarities. There is a very high probability of dropping gold, silver and other special material cards.",
    dialogTitle: "Faction loot box\uFF1A",
    card: true,
    card2: true,
    bgc: "bgking.png",
    price: ""
  },
  {
    BoxSrc: "mybox/box6big.png",
    BoxSrcBig: "mybox/box6big.png",
    qSrc: "qian.png",
    money: [6, 2],
    BTN: "BUY",
    typeBox: 6,
    title: "EPIC LOOT BOX",
    titleshor: "EPIC LOOT BOX",
    content: "IS A PRECIOUS LOOT BOX THAT CONTAINS NFT HERO CARDS OF ALL MATERIALS AND RARITIES. THERE IS A HIGH PROBABILITY OF DROPPING GOLD, SILVER AND OTHER SPECIAL MATERIAL CARDS.",
    dialogTitle: "Card Pack Introduction:",
    card: true,
    card2: true,
    bgc: "bgzi.png",
    price: ""
  },
  {
    BoxSrc: "mybox/box4big.png",
    BoxSrcBig: "mybox/box4big.png",
    qSrc: "qian.png",
    money: [4, 3],
    BTN: "BUY",
    typeBox: 4,
    title: "STANDARD LOOT BOX",
    titleshor: "STANDARD LOOT BOX",
    content: "is a common loot box that contains NFT hero cards of different identities from all factions. It can only drop NFT hero cards of black iron material.",
    dialogTitle: "Card Pack Introduction:",
    card: true,
    card2: true,
    bgc: "bghui.png",
    price: ""
  },
  {
    BoxSrc: "mybox/box17big.png",
    BoxSrcBig: "mybox/box17big.png",
    qSrc: "qian.png",
    money: [6, 2],
    BTN: "BUY",
    typeBox: 17,
    title: "SPACESHIP LOOT BOX",
    titleshor: "SPACESHIP LOOT BOX",
    content: "is an advanced spaceship loot box. The box contains 10 advanced spaceships which can be used for interstellar exploration and will be mapped 1:1 to the V3 game main net in the future.",
    dialogTitle: "SPACESHIP LOOT BOX:",
    isShip: true,
    card: false,
    card2: true,
    bgc: "bgxing.png",
    price: ""
  }
];
const getItemByType = (type) => {
  let item = cardList.find((item2) => item2.typeBox == type);
  if (!item) {
    return {};
  }
  if ([18].includes(item.typeBox)) {
    item.rank = "Uncommon";
  } else if ([8, 19, 7, 17].includes(item.typeBox)) {
    item.rank = "Legendary";
  } else {
    item.rank = "Common";
  }
  return item;
};

const lootbox$2 = {
  __proto__: null,
  cardList: cardList,
  getItemByType: getItemByType
};

const levelCoors = {
  1: "#B6B6B6",
  2: "#B6B6B6",
  3: "#B6B6B6",
  4: "#9C07FF",
  5: "#9C07FF",
  6: "#9C07FF",
  7: "#9C07FF",
  8: "#FFCD07",
  9: "#FFCD07",
  10: "#FFCD07"
};
const profession = {
  0: "Omni Warrior",
  1: "Psionicist",
  2: "Bounty Hunter",
  3: "Wanderer",
  4: "Liquidator"
};
const rarityImage = {
  0: "/cardbg1.png",
  1: "/cardbg2.png",
  2: "/cardbg3.png",
  3: "/cardbg4.png",
  7: "/card-bg-material.png",
  epic: "/card-bg-material.png"
};
const levelImage = {
  1: "/lv1.png",
  2: "/lv2.png",
  3: "/lv3.png",
  4: "/lv4.png",
  5: "/lv5.png",
  6: "/lv6.png"
};
const rarity = {
  0: "Common",
  1: "Rare",
  2: "Epic",
  3: "Legend"
};
const material = {
  0: "DarkIron",
  1: "Silver",
  2: "Gold"
};
const identity = {
  0: "Leader",
  1: "Scientist",
  2: "Commander",
  3: "Star Soldier",
  4: "Common Soldier"
};
const identityImages = {
  0: "leader",
  1: "scientist",
  2: "commander",
  3: "star_soldier",
  4: "common_soldier"
};
const camp = {
  0: "Asman Zerg",
  1: "Empire of Amda",
  2: "Confederation of Earth",
  3: "Eternal Protoss"
};
const egseType = {
  1: "NORMAL",
  2: "RARE",
  3: "EPIC"
};
const pseType = {
  1: "TypeI",
  2: "TypeII",
  3: "Star",
  4: "Galaxy",
  5: "Eternal",
  6: "blackbox",
  7: "golddigger",
  8: "Trabilizer"
};
const eggType = {
  1: "Confederation Of Earth",
  2: "Empire of Amda",
  3: "Eternal Protoss"
};
const spaceshipType = {
  1: "common",
  2: "Rare",
  3: "Epic",
  4: "Legend"
};
const equipType = {
  1: "Common",
  2: "Rare",
  3: "Epic",
  4: "Legendary"
};
const spaceshipTypeColor = {
  COMMON: "#8696C0",
  RARE: "#29BDFF",
  EPIC: "#9D00FF",
  LEGENDARY: "#FFD800"
};
const lootBoxType = {
  1: "Halloween Loot Box",
  2: "EGSE_Normal",
  3: "EGSE_Rare",
  4: "EGSE_Epic",
  5: "Standard Loot Box",
  6: "Premium Loot Box",
  7: "Epic Loot Box",
  8: "Legendary Loot Box",
  18: "Spaceship Loot Box"
};
const materialType = {
  1: "Common",
  2: "Rare",
  3: "Epic",
  4: "legend"
};
const cardType = {
  card: 0,
  halloween: 1,
  pse: 2,
  egse: 3,
  spaceship: 4,
  lootbox: 6,
  material: 8,
  egg: 5,
  equipment: 7
};
const equipmentType = {
  "01": "Protector Helmet",
  "02": "Energy Cuirass",
  "03": "Protector Leggings",
  "04": "Protector Cuirass",
  "05": "Kandal's Helmet",
  "06": "Kandal's Cuirass",
  "07": "Kandal's Leggings",
  "08": "Spectre Cuirass",
  "09": "Marauders Cuirass",
  "10": "Marauders Leggings",
  "11": "Marauders Helmet",
  "12": "Multi-thread",
  "13": "Lacerator",
  "14": "Marauders Rifle",
  "15": "Marauders Pistol",
  "16": "Kandal's Sword",
  "17": "Kandal's Sharp Claws"
};
function nftTypeIs(type, currentNftType) {
  if (Array.isArray(type)) {
    return type.some((t) => currentNftType === cardType[t]);
  } else {
    return currentNftType === cardType[type];
  }
}

const constant = {
  __proto__: null,
  levelCoors: levelCoors,
  profession: profession,
  rarityImage: rarityImage,
  levelImage: levelImage,
  rarity: rarity,
  material: material,
  identity: identity,
  identityImages: identityImages,
  camp: camp,
  egseType: egseType,
  pseType: pseType,
  eggType: eggType,
  spaceshipType: spaceshipType,
  equipType: equipType,
  spaceshipTypeColor: spaceshipTypeColor,
  lootBoxType: lootBoxType,
  materialType: materialType,
  cardType: cardType,
  equipmentType: equipmentType,
  nftTypeIs: nftTypeIs
};

const poolList = [
  {
    id: 1,
    className: "",
    name: "Standard Pool",
    discTitle: "Limited Rewards",
    disc: "You have change to get a premium loot Box",
    nftName: "Premium loot Box",
    nftDesc: `Premium loot box is a common loot box that contains NFT hero cards of all materials and
    rarities. There is a certain probability of dropping gold, silver and other special
    material cards.`,
    chainInfo: {}
  },
  {
    id: 2,
    className: "epic",
    name: "Epic Pool",
    discTitle: "Limited Rewards",
    disc: "You have change to get a premium Epic loot Box",
    nftName: "Epic loot Box",
    nftDesc: `Epic loot box is a precious loot box that contains NFT hero cards of all
    materials and rarities. There is a high probability of dropping gold, silver
    and other special material cards.`,
    chainInfo: {}
  },
  {
    id: 3,
    className: "legend",
    name: "Legendary Pool",
    discTitle: "Limited Rewards",
    disc: "You have change to get a Legendary loot Box",
    nftName: "Legendary loot Box",
    nftDesc: `Legendary loot box is an extremely valuable loot boxes with limited supply that contains
    NFT hero cards of all materials and rarities. There is a very high probability of
    dropping gold, silver and other special material cards.`,
    chainInfo: {}
  }
];
function getPoolBasicInfo(poolId) {
  const target = poolList.find((pool) => pool.id === poolId);
  if (target) {
    return target;
  } else {
    console.error("getPoolBasicInfo error: poolId not found");
    return poolList[0];
  }
}

const stake = {
  __proto__: null,
  poolList: poolList,
  getPoolBasicInfo: getPoolBasicInfo
};

const objToArr = (obj) => [
  { value: -1, label: "All" },
  ...Object.keys(obj).map((key) => ({
    value: Number(key),
    label: obj[Number(key)]
  }))
];
const options1 = [
  {
    value: -1,
    label: "All"
  },
  {
    value: cardType.card,
    label: "Hero Card"
  },
  {
    value: cardType.pse,
    label: "PSE"
  },
  {
    value: cardType.halloween,
    label: "HSE"
  },
  {
    value: cardType.spaceship,
    label: "SPACESHIP"
  },
  {
    value: cardType.egg,
    label: "Egg"
  },
  {
    value: cardType.lootbox,
    label: "Lootbox"
  },
  {
    value: cardType.material,
    label: "Material"
  },
  {
    value: cardType.equipment,
    label: "Equipment"
  }
];
const options2 = objToArr(camp);
const options3 = objToArr(rarity);
const options4 = objToArr(pseType);
const options5 = objToArr(egseType);
const options6 = objToArr(spaceshipType);
const options7 = Array(10).fill(0).map((_item, index) => {
  if (index === 0) {
    return { value: -1, label: "All" };
  }
  return { value: index, label: String(index) };
});

const statistics$1 = {
  __proto__: null,
  options1: options1,
  options2: options2,
  options3: options3,
  options4: options4,
  options5: options5,
  options6: options6,
  options7: options7
};

const index$2 = {
  __proto__: null,
  lootbox: lootbox$2,
  constant: constant,
  stake: stake,
  statistics: statistics$1
};

async function getSigner(options) {
  if (process.env.FIREWORK_ENV) {
    const signer = new ethers.providers.Web3Provider(ethereum);
    await signer.send("eth_requestAccounts", []);
    return signer.getSigner();
  }
  const provider = new ethers.providers.JsonRpcProvider(options?.provider);
  const wallet = new ethers.Wallet(options?.privateKey, provider);
  return wallet;
}
function isMetaMask() {
  return Boolean(ethereum && ethereum.isMetaMask);
}
async function getChainId() {
  try {
    return await getWalletEnvironment().request({ method: "eth_chainId" });
  } catch (err) {
    console.error(err);
    return "";
  }
}
function tansParams(params) {
  let result = "";
  for (const propName of Object.keys(params)) {
    const value = params[propName];
    var part = encodeURIComponent(propName) + "=";
    if (value !== null && typeof value !== "undefined") {
      if (typeof value === "object") {
        for (const key of Object.keys(value)) {
          if (value[key] !== null && typeof value[key] !== "undefined") {
            let params2 = propName + "[" + key + "]";
            var subPart = encodeURIComponent(params2) + "=";
            result += subPart + encodeURIComponent(value[key]) + "&";
          }
        }
      } else {
        result += part + encodeURIComponent(value) + "&";
      }
    }
  }
  return result;
}
async function getWalletAddress() {
  const provider = getWalletEnvironment();
  const [account] = await provider.request({ method: "eth_requestAccounts" });
  return account;
}
async function getWalletNetwork() {
  const provider = getWalletEnvironment();
  return await provider.request({ method: "eth_chainId" });
}
function getWalletEnvironment() {
  const type = localStorage.getItem("walletExtension");
  switch (type) {
    case "BitKeep":
      return window.bitkeep.ethereum;
    default:
      return window.ethereum;
  }
}
function verifyWalletAddress(address) {
  return ethers.utils.getAddress(address);
}

const utils$1 = {
  __proto__: null,
  getSigner: getSigner,
  isMetaMask: isMetaMask,
  getChainId: getChainId,
  tansParams: tansParams,
  getWalletAddress: getWalletAddress,
  getWalletNetwork: getWalletNetwork,
  getWalletEnvironment: getWalletEnvironment,
  verifyWalletAddress: verifyWalletAddress
};

const cookies$1 = new Cookies();
const TokenKey$1 = env.AUTHTOKEN_NAME;
function getToken$1() {
  return cookies$1.get(TokenKey$1);
}
const serviceNodeJs$1 = axios.create({
  baseURL: env.javaUrl,
  timeout: 1e4
});
serviceNodeJs$1.interceptors.request.use(
  (config) => {
    const isToken = (config.headers || {}).isToken === false;
    if (getToken$1() && !isToken) {
      config.headers["authToken"] = getToken$1();
    }
    if (config.method === "get" && config.params) {
      let url = config.url + "?" + tansParams(config.params);
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);
serviceNodeJs$1.interceptors.response.use(
  (res) => {
    return Promise.resolve(res.data);
  },
  (error) => {
    return Promise.reject(error);
  }
);

async function isApprovedForAll(openerAddr, operator) {
  const account = await getWalletAddress();
  const data = await IERC721Contract(
    openerAddr,
    IERC721$1,
    await getSigner()
  ).isApprovedForAll(account, operator);
  return data;
}
async function setApprovalForAll(openerAddr, operator) {
  const data = await IERC721Contract(
    openerAddr,
    IERC721$1,
    await getSigner()
  ).setApprovalForAll(operator, true);
  await data.wait().then(console.log);
  return data;
}
async function getPastEvents(openerAddr) {
  const account = await getWalletAddress();
  const Contract = IERC721Contract(
    openerAddr,
    IERC721$1,
    await getSigner()
  );
  console.log("IERC721Contract", Contract);
  const filterFrom = Contract.filters.Transfer(null, account);
  console.log("filterFrom", filterFrom);
  const data = await Contract.queryFilter(-1e3);
  console.log("queryFilter", data);
  return data;
}
async function getTokenURIByTokenId(tokenId, cardAddr) {
  cardAddr = cardAddr || env.FireworkCardAddr;
  const data = await erc721YContract(cardAddr, erc721Y, await getSigner()).tokenURI(tokenId);
  return data;
}
async function getInfoByTokenURI(tokenURI) {
  return await fetch(`${tokenURI}?v=${new Date().getTime()}`).then((response) => response.json()).then((obj) => {
    obj.attrs = {};
    obj.attributes.forEach(({ trait_type, value }) => {
      obj.attrs[trait_type] = value;
    });
    return obj;
  });
}
async function getNftListByTransferType(params) {
  const res = await serviceNodeJs$1.get("/api/v1/gameRelate/getGameNft", {
    params
  });
  return res.data.records?.filter((item) => item.tokenId);
}

const IERC721 = {
  __proto__: null,
  isApprovedForAll: isApprovedForAll,
  setApprovalForAll: setApprovalForAll,
  getPastEvents: getPastEvents,
  getTokenURIByTokenId: getTokenURIByTokenId,
  getInfoByTokenURI: getInfoByTokenURI,
  getNftListByTransferType: getNftListByTransferType
};

async function getERC20Balance(tokenAddress) {
  const account = await getWalletAddress();
  const data = await erc20Contract(tokenAddress, erc20, await getSigner()).balanceOf(account);
  return utils$2.formatEther(data);
}
async function getFireTokenBalance() {
  return getERC20Balance(env.TOKEN_ERC20_FIRE);
}
async function getUsdtTokenBalance() {
  return getERC20Balance(env.TOKEN_ERC20_USDT);
}
async function getERC20Allowance(tokenAddress, spenderAddress) {
  const account = await getWalletAddress();
  const data = await erc20Contract(tokenAddress, erc20, await getSigner()).allowance(
    account,
    spenderAddress
  );
  return utils$2.formatEther(data);
}
async function getFireTokenAllowanceOfStake() {
  return getERC20Allowance(env.TOKEN_ERC20_FIRE, env.STAKEAddr);
}
async function setERC20Allowance(tokenAddress, spenderAddress, amount) {
  const data = await erc20Contract(tokenAddress, erc20, await getSigner()).approve(
    spenderAddress,
    amount
  );
  const res = await data.wait();
  return res;
}
async function setFireTokenAllowanceOfStake(amount) {
  return setERC20Allowance(
    env.TOKEN_ERC20_FIRE,
    env.STAKEAddr,
    utils$2.parseEther(amount)
  );
}

const ERC20 = {
  __proto__: null,
  getERC20Balance: getERC20Balance,
  getFireTokenBalance: getFireTokenBalance,
  getUsdtTokenBalance: getUsdtTokenBalance,
  getERC20Allowance: getERC20Allowance,
  getFireTokenAllowanceOfStake: getFireTokenAllowanceOfStake,
  setERC20Allowance: setERC20Allowance,
  setFireTokenAllowanceOfStake: setFireTokenAllowanceOfStake
};

async function queryPrice(lootboxTypeId, options) {
  const contract = IBlindBoxBlackSmithContract(
    env.BlindBoxBlackSmithAddr,
    IBlindBoxBlackSmith,
    await getSigner(options)
  );
  const data = await contract.queryPriceInErc20(lootboxTypeId).then((res) => utils$2.formatUnits(res, "ether"));
  return data;
}
async function queryPriceWhitelist(lootboxTypeId, quantity, options) {
  const data = await IBlindBoxBlackSmithContract(
    env.BlindBoxBlackSmithAddr,
    IBlindBoxBlackSmith,
    await getSigner(options)
  ).queryPriceInNativeTokenConsiderWhitelist(lootboxTypeId, quantity).then((res) => utils$2.formatUnits(res));
  return data;
}
async function queryPricePse(lootboxTypeId, quantity, options) {
  const data = await IBlindBoxBlackSmithContract(
    env.BlindBoxBlackSmithAddr,
    IBlindBoxBlackSmith,
    await getSigner(options)
  ).queryPriceInNativeTokenConsiderPse(lootboxTypeId, quantity).then((res) => utils$2.formatUnits(res));
  return data;
}
async function queryGlobalLimitAndSoldAmount(typeId) {
  const data = await IBlindBoxBlackSmithContract(
    env.BlindBoxBlackSmithAddr,
    IBlindBoxBlackSmith,
    await getSigner()
  ).queryGlobalLimitAndSoldAmount(typeId).then((res) => {
    console.log("queryGlobalLimitAndSoldAmount", res);
    return {
      limit: Number(utils$2.formatUnits(res["limit"], 0)),
      soldAmount: Number(utils$2.formatUnits(res["soldAmount"], 0))
    };
  });
  return data;
}
async function queryTodayBoughtQty(typeId) {
  const [account] = await getWalletEnvironment().request({ method: "eth_requestAccounts" });
  const typeList = [7, 17, 18];
  const data = await IBlindBoxBlackSmithContract(
    env.BlindBoxBlackSmithAddr,
    IBlindBoxBlackSmith,
    await getSigner()
  ).queryPlayerBoughtQty(account, typeList).then((res) => {
    const obj = {};
    console.log("queryPlayerBoughtQty", res);
    typeList.forEach((key, index) => {
      obj[key] = Number(utils$2.formatUnits(res["typLimitPerAddr"][index], 0));
      obj[`${key}_${key}`] = Number(utils$2.formatUnits(res["boughtNumber"][index], 0));
    });
    console.log("obj", obj);
    return obj;
  });
  return {
    typeId,
    boughtQty: data[`${typeId}_${typeId}`],
    limitQty: data[typeId]
  };
}
async function buyLootbox(lootboxTypeId, quantity, price) {
  const value = utils$2.parseUnits(price).toString();
  console.log(lootboxTypeId, quantity, price, value);
  const Contract = IBlindBoxBlackSmithContract(
    env.BlindBoxBlackSmithAddr,
    IBlindBoxBlackSmith,
    await getSigner()
  );
  const data = await Contract.buyWithErc20(lootboxTypeId, quantity);
  console.log("buyWithErc20 data", data);
  const { blockNumber } = await data.wait();
  const res = await queryLootboxIdByBlockHeight(blockNumber, price, lootboxTypeId, data.hash);
  return res;
}
async function queryOpenerAddr(lootboxTypeId) {
  const data = await IBlindBoxOpenerManagerContract(
    env.BlindBoxOpenerManagerAddr,
    IBlindBoxOpenerManager,
    await getSigner()
  ).queryOpenerAddr(lootboxTypeId);
  console.log("queryOpenerAddr data", data);
  return data;
}
async function queryLootboxIdByBlockHeight(blockHeight, price, lootboxTypeId, txHash) {
  const opContract = IERC721Contract(env.BlindBoxAddr, IERC721$1, await getSigner());
  console.log("opContract", opContract);
  const filterFrom = opContract.filters.Transfer();
  const data = opContract.queryFilter(filterFrom, blockHeight, blockHeight).then((res) => {
    console.log(
      "queryFilter",
      res.map((item) => utils$2.formatUnits(item.args["tokenId"], 0))
    );
    GoogleAnalyticsPurchase(price, "BNB", txHash, [
      {
        item_id: res.map((item) => utils$2.formatUnits(item.args["tokenId"], 0)),
        item_name: lootboxTypeId,
        affiliation: "marketplace"
      }
    ]);
    return res.map((item) => utils$2.formatUnits(item.args["tokenId"], 0));
  });
  return data;
}
async function lootboxIsApprovedForAll(openerAddr) {
  const BlindBoxAddr = env.BlindBoxAddr || "";
  const data = await isApprovedForAll(BlindBoxAddr, openerAddr);
  return data;
}
async function lootboxSetApprovalForAll(openerAddr) {
  const BlindBoxAddr = env.BlindBoxAddr || "";
  const data = await setApprovalForAll(BlindBoxAddr, openerAddr);
  return data;
}
async function batchOpen(openerAddr, nftIds) {
  console.log("batchOpen", openerAddr, nftIds);
  const res = await IBlindBoxOpenerContract(
    openerAddr,
    IBlindBoxOpener,
    await getSigner()
  ).batchOpenWithIds(nftIds, { gasLimit: 5e6 });
  console.log("batchOpen res", res);
  const data = await res.wait();
  console.log("batchOpen data", data);
  return data;
}
async function sleep(sec) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(void 0);
    }, sec * 1e3);
  });
}
async function checkOpen(blockNumber) {
  const assetAddr = await IBlindBoxOpenerContract(
    env.BBoxOpenerTyp1Addr,
    IBlindBoxOpener,
    await getSigner()
  ).assetAddr();
  const opContract = IERC721Contract(assetAddr[0], IERC721$1, await getSigner());
  const filterFrom = opContract.filters.Transfer();
  const data = opContract.queryFilter(filterFrom, blockNumber, blockNumber).then((res) => {
    console.log("queryFilter", res);
    console.log(
      "queryFilter",
      res.map((item) => utils$2.formatUnits(item.args["tokenId"], 0))
    );
    return res.map((item) => utils$2.formatUnits(item.args["tokenId"], 0));
  });
  return data;
}
async function checkOpenShip(blockNumber) {
  console.log("checkOpenShip", blockNumber);
  const opContract = IERC721Contract(env.StarShipAddr, IERC721$1, await getSigner());
  console.log("opContract", opContract);
  const filterFrom = opContract.filters.Transfer();
  const data = opContract.queryFilter(filterFrom, blockNumber, blockNumber).then((res) => {
    console.log("queryFilter", res);
    console.log(
      "queryFilter",
      res.map((item) => utils$2.formatUnits(item.args["tokenId"], 0))
    );
    return res.map((item) => utils$2.formatUnits(item.args["tokenId"], 0));
  });
  return data;
}
async function queryUserBoxes(fetchRes) {
  console.log("queryUserBoxes fetchRes:", fetchRes);
  let list = [];
  let arr2 = [];
  if (!fetchRes) {
    const [account] = await getWalletEnvironment().request({
      method: "eth_requestAccounts"
    });
    list = await IBlindBoxContract(
      env.BlindBoxAddr,
      IBlindBox,
      await getSigner()
    ).queryUserBoxes(account);
    const arr = list.map((item) => utils$2.formatUnits(item, 0));
    console.log("queryUserBoxes", arr);
    const obj = {};
    for (let index = 0; index < arr.length; index = index + 2) {
      const tokenId = arr[index];
      const type = arr[index + 1];
      if (!obj[type])
        obj[type] = [];
      obj[type].push(tokenId);
    }
    console.log("queryUserBoxes obj", obj);
    arr2 = Object.keys(obj).map((key) => ({
      ...getItemByType(Number(key)),
      type: Number(key),
      list: obj[key]
    }));
  } else {
    const obj = {};
    fetchRes.forEach((item) => {
      const tokenId = item.token_id;
      const type = item.tokenURI.split("/").slice(-1)[0] - 0;
      if (!obj[type])
        obj[type] = [];
      obj[type].push(tokenId);
    });
    console.log("queryUserBoxes obj", obj);
    arr2 = Object.keys(obj).map((key) => ({
      ...getItemByType(Number(key)),
      type: Number(key),
      list: obj[key]
    }));
  }
  return arr2;
}
async function getBalance() {
  const [account] = await getWalletEnvironment().request({ method: "eth_requestAccounts" });
  const provider = new ethers.providers.Web3Provider(getWalletEnvironment());
  const price = await provider.getBalance(account);
  return utils$2.formatUnits(price, 18);
}
async function getTokenAllowance() {
  return getERC20Allowance(env.TOKEN_ERC20_USDT, env.BlindBoxBlackSmithAddr);
}
async function setTokenAllowance(amount) {
  return setERC20Allowance(
    env.TOKEN_ERC20_USDT,
    env.BlindBoxBlackSmithAddr,
    utils$2.parseEther(amount)
  );
}
async function getLootboxData(tokenId) {
  const url = await getTokenURIByTokenId(tokenId, env.BlindBoxAddr);
  const info = await getInfoByTokenURI(url);
  return info;
}
async function setFireTokenAllowance(amount) {
  return setERC20Allowance(
    env.TOKEN_ERC20_FIRE,
    env.AssetManagerAddr,
    utils$2.parseEther(amount)
  );
}

const lootbox$1 = {
  __proto__: null,
  queryPrice: queryPrice,
  queryPriceWhitelist: queryPriceWhitelist,
  queryPricePse: queryPricePse,
  queryGlobalLimitAndSoldAmount: queryGlobalLimitAndSoldAmount,
  queryTodayBoughtQty: queryTodayBoughtQty,
  buyLootbox: buyLootbox,
  queryOpenerAddr: queryOpenerAddr,
  queryLootboxIdByBlockHeight: queryLootboxIdByBlockHeight,
  lootboxIsApprovedForAll: lootboxIsApprovedForAll,
  lootboxSetApprovalForAll: lootboxSetApprovalForAll,
  batchOpen: batchOpen,
  sleep: sleep,
  checkOpen: checkOpen,
  checkOpenShip: checkOpenShip,
  queryUserBoxes: queryUserBoxes,
  getBalance: getBalance,
  getTokenAllowance: getTokenAllowance,
  setTokenAllowance: setTokenAllowance,
  getLootboxData: getLootboxData,
  setFireTokenAllowance: setFireTokenAllowance
};

async function getQueryFilter({ pageNum = 1, pageSize = 10, ...routeQuery } = {}) {
  const account = await getWalletAddress();
  let query = {
    pageNumber: pageNum,
    pageSize,
    address: account,
    nftType: 0
  };
  const object = routeQuery;
  for (const key in object) {
    if (Object.hasOwnProperty.call(object, key)) {
      const element = object[key];
      switch (key) {
        case "faction":
          query.camp = element;
          break;
        default:
          if (object[key]) {
            query[key] = element;
          }
          break;
      }
      if (typeof element === "string" && element.includes(",")) {
        const [min, max] = element.split(",");
        query[`min${key}`] = min;
        query[`max${key}`] = max;
      }
    }
  }
  if (Number(query.nftType) === cardType.spaceship && query.rank) {
    query.rank = spaceshipType[query.rank];
  }
  if (query.hasOwnProperty("pse")) {
    query.type = query.pse;
  }
  if (query.hasOwnProperty("egse")) {
    query.type = query.egse;
  }
  return query;
}
async function fetchList$1(query = {}, listType = "assets") {
  const baseUrl = env.javaUrl;
  let apiUrl = "/api/v1/informationCenter/myStorage";
  if (listType === "selling") {
    apiUrl = "/api/v1/informationCenter/selling";
  } else if (listType === "favorite") {
    apiUrl = "/api/v1/informationCenter/myFavorite";
  } else if (listType === "market") {
    apiUrl = "/api/v1/marketplace/tradingMarket";
  }
  if (Number(query.nftType) === cardType.lootbox) {
    query.pageSize = 9999;
  }
  const res = await axios.get(baseUrl + apiUrl, {
    params: query
  });
  let cardList = [];
  let total = 0;
  const account = await getWalletAddress();
  if (Number(query.nftType) === cardType.halloween) {
    cardList = [res.data.data.records[0]];
    cardList[0].tokenIdList = [];
    res.data.data.records.forEach((ele) => {
      cardList[0].isSale = ele.hasOwnProperty("price");
      cardList[0].isOwner = ele.owner_address === account;
      cardList[0].tokenIdList.push(ele.tokenId || ele.token_id);
    });
    total = res.data.data.total;
  } else if (Number(query.nftType) === cardType.lootbox) {
    if (listType === "assets") {
      cardList = await queryUserBoxes(res.data.data.records.filter((item) => !item.isSelling));
      total = cardList.length;
    } else {
      cardList = res.data.data.records;
      total = res.data.data.total;
    }
    cardList.forEach((ele) => {
      ele.isSale = ele.hasOwnProperty("price");
      ele.isOwner = ele.owner_address === account;
    });
  } else {
    cardList = res.data.data.records;
    cardList.forEach((ele) => {
      ele.isSale = ele.hasOwnProperty("price");
      ele.isOwner = ele.owner_address === account || ele.ownerAddress === account;
    });
    total = res.data.data.total;
  }
  return { cardList, total };
}
async function tokenDetailByTokenId(tokenId, nftType) {
  const address = await getWalletAddress();
  const params = {
    tokenId,
    nftType,
    address
  };
  const res = await serviceNodeJs$1.get("/api/v1/informationCenter/tokenDetailByTokenId", {
    params
  });
  res.data.isOwner = res.data.owner_address === address || res.data.ownerAddress === address;
  return res;
}
async function transferAssetsToGame(params) {
  return await transferShipForContract(params);
}
async function transferShipForContract(params) {
  const account = await getWalletAddress();
  params.account = params.account || account;
  console.log("transferShipForContract", params);
  switch (params.transferType) {
    case 1:
      await setFireTokenAllowance(params.fireToken);
      return await IFireworkAssetManagerContract(
        env.AssetManagerAddr,
        IFireworkAssetManager,
        await getSigner()
      ).swapTokenToGame(
        params.account,
        params.toGame,
        [env.TOKEN_ERC20_FIRE],
        [utils$2.parseEther(params.fireToken)]
      );
    default:
      return await IFireworkAssetManagerContract(
        env.AssetManagerAddr,
        IFireworkAssetManager,
        await getSigner()
      ).swapNftToGame(params.account, params.toGame, params.contractAddress, params.nftIds);
  }
}
async function transferAssetsToWallet(params) {
  try {
    return await transferBalanceForContract(params);
  } catch (error) {
    console.log(error);
  }
}
async function getSignWithTransferType(params) {
  let sign = null;
  let responseData = null;
  switch (params.transferType) {
    case 1:
      const tokenQueryStr = `?fire=${params.FireNumber}&gameTag=${params.gameTag}`;
      const tokenData = await serviceNodeJs$1.post(
        env.javaUrl + "/api/v1/gameRelate/takeOutTokenSign" + tokenQueryStr,
        null
      );
      responseData = tokenData.data;
      break;
    default:
      const queryStr = `?takeOut=${params.takeOut}&gameTag=${params.gameTag}&nftType=${params.nftType}`;
      const data = await serviceNodeJs$1.post(
        env.javaUrl + "/api/v1/gameRelate/takeOutSign" + queryStr,
        null
      );
      responseData = data.data;
      break;
  }
  let { claim_content, r, s, v } = responseData;
  claim_content = "0x" + claim_content;
  r = "0x" + r;
  s = "0x" + s;
  v = "0x" + v;
  sign = {
    claim_content,
    v,
    r,
    s
  };
  return sign;
}
async function transferBalanceForContract(params) {
  const sign = await getSignWithTransferType(params);
  const account = await getWalletAddress();
  switch (params.transferType) {
    case 1:
      return await IFireworkAssetManagerContract(
        env.AssetManagerAddr,
        IFireworkAssetManager,
        await getSigner()
      ).claimTokenToChain(account, sign.claim_content, sign.v, sign.r, sign.s);
    default:
      return await IFireworkAssetManagerContract(
        env.AssetManagerAddr,
        IFireworkAssetManager,
        await getSigner()
      ).claimNftToChain(account, sign.claim_content, sign.v, sign.r, sign.s);
  }
}
async function getGameToken() {
  const data = await serviceNodeJs$1.get("/api/v1/gameRelate/getGameToken", {
    params: {
      gameTag: 1
    }
  });
  console.log("getGameToken", data);
  return data;
}

const storage$1 = {
  __proto__: null,
  getQueryFilter: getQueryFilter,
  fetchList: fetchList$1,
  tokenDetailByTokenId: tokenDetailByTokenId,
  transferAssetsToGame: transferAssetsToGame,
  transferAssetsToWallet: transferAssetsToWallet,
  getGameToken: getGameToken
};

const cookies = new Cookies();
const TokenKey = env.AUTHTOKEN_NAME;
function getToken() {
  return cookies.get(TokenKey);
}
const serviceNodeJs = axios.create({
  baseURL: env.nodeUrl,
  timeout: 1e4
});
serviceNodeJs.interceptors.request.use(
  (config) => {
    const isToken = (config.headers || {}).isToken === false;
    if (getToken() && !isToken) {
      config.headers["authToken"] = getToken();
    }
    if (config.method === "get" && config.params) {
      let url = config.url + "?" + tansParams(config.params);
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);
serviceNodeJs.interceptors.response.use(
  (res) => {
    return Promise.resolve(res.data);
  },
  (error) => {
    return Promise.reject(error);
  }
);

async function getUserEmailByWalletAddress(walletAddress) {
  if (!walletAddress) {
    if (getWalletEnvironment()) {
      const account = await getWalletAddress();
      walletAddress = account;
    }
  }
  return await serviceNodeJs.get(`/api/user/` + walletAddress);
}
const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const regexPwd = /(?!^\d+$)(?!^[A-Za-z]+$)(?!^[^A-Za-z0-9]+$)(?!^.*[\u4E00-\u9FA5].*$)^\S{6,18}$/;
const hash = (str) => Md5.hashStr(`Firework${str}Games`);
async function login(userName, pwd, requestConfig) {
  if (!regexEmail.test(userName)) {
    return Promise.reject(new Error("The user name format is incorrect"));
  }
  if (pwd.length < 6 || pwd.length > 18) {
    return Promise.reject(new Error("The password contains 6 to 18 characters"));
  }
  return serviceNodeJs.post(
    "/basic/login",
    {
      UserName: userName,
      Passsword: hash(pwd)
    },
    requestConfig
  );
}
async function sendCode(userName) {
  if (!regexEmail.test(userName)) {
    return Promise.reject(new Error("Please enter a valid email"));
  }
  return await serviceNodeJs.post("/basic/sendCode", {
    Name: userName
  });
}
async function register(userName, pwd, code, invCode) {
  if (!regexEmail.test(userName)) {
    return Promise.reject(new Error("The user name format is incorrect"));
  }
  if (pwd.length < 6 || pwd.length > 18 || !regexPwd.test(pwd)) {
    return Promise.reject(
      new Error(
        "The password contains 6 to 18 characters, including at least letters, digits, and symbols, and does not contain Spaces"
      )
    );
  }
  return serviceNodeJs.post("/basic/userInfo", {
    UserName: userName,
    Passsword: hash(pwd),
    Code: code,
    RefferAddr: invCode
  });
}
async function bindWallet(LoginType, BindType, Address, UserName) {
  return await serviceNodeJs.post(`/sso/bindWalletWithAccount`, {
    LoginType,
    BindType,
    Address,
    UserName
  });
}
async function sendCodeFromForgetPwd(userName) {
  if (!regexEmail.test(userName)) {
    return Promise.reject(new Error("Please enter a valid email"));
  }
  return await serviceNodeJs.post("/basic/sendCodeToForgetPwd", {
    Name: userName
  });
}
async function validateForgetCode(userName, code) {
  if (!regexEmail.test(userName)) {
    return Promise.reject(new Error("Please enter a valid email"));
  }
  return await serviceNodeJs.post("/basic/validateForgetCode", {
    UserName: userName,
    Code: code
  });
}
async function changePwd(userName, pwd, code) {
  if (!regexEmail.test(userName)) {
    return Promise.reject(new Error("Please enter a valid email"));
  }
  if (pwd.length < 6 || pwd.length > 18 || !regexPwd.test(pwd)) {
    return Promise.reject(
      new Error(
        "The password contains 6 to 18 characters, including at least letters, digits, and symbols, and does not contain Spaces"
      )
    );
  }
  return await serviceNodeJs.post("/basic/forgetPwd", {
    UserName: userName,
    Passsword: hash(pwd),
    Code: code
  });
}
async function logout() {
  return await serviceNodeJs.get("/sso/loginOut");
}
async function getUserInfo() {
  return await serviceNodeJs.get("/sso/getUserInfo");
}
async function userInfoValidate(key, value) {
  if (key === "email") {
    if (regexEmail.test(value)) {
      return await serviceNodeJs.post("/sso/userInfoValidate", { UserName: value });
    } else {
      return Promise.reject(new Error("Invalid email address"));
    }
  }
  return Promise.reject(new Error("userInfoValidate: error key"));
}
async function loginOther(LoginType, accessToken) {
  return await serviceNodeJs.post("/sso/loginOther", { LoginType, accessToken });
}
async function signWithCurrentWallet(msg) {
  let signature = "";
  if (getWalletEnvironment()) {
    const jsonRpcProvider = new ethers.providers.Web3Provider(getWalletEnvironment());
    const signer = jsonRpcProvider.getSigner();
    signature = await signer.signMessage(msg);
  }
  return signature;
}
async function verifySignedMessage(msg, signature) {
  const account = await getWalletAddress();
  const signerAddr = ethers.utils.verifyMessage(msg, signature);
  return signerAddr === account;
}

const user = {
  __proto__: null,
  getUserEmailByWalletAddress: getUserEmailByWalletAddress,
  regexEmail: regexEmail,
  login: login,
  sendCode: sendCode,
  register: register,
  bindWallet: bindWallet,
  sendCodeFromForgetPwd: sendCodeFromForgetPwd,
  validateForgetCode: validateForgetCode,
  changePwd: changePwd,
  logout: logout,
  getUserInfo: getUserInfo,
  userInfoValidate: userInfoValidate,
  loginOther: loginOther,
  signWithCurrentWallet: signWithCurrentWallet,
  verifySignedMessage: verifySignedMessage
};

async function getInviteCodeByEmail() {
  console.log("getInviteCodeByEmail");
  return await serviceNodeJs$1.get("/api/v1/informationCenter/getInviteCode");
}
async function getRebateInfo() {
  return await serviceNodeJs$1.get("/api/v1/informationCenter/showRebateInfo");
}
async function getRebateSign(emailAddr, erc20Addr) {
  return await serviceNodeJs$1.get("/api/v1/informationCenter/getRebate", {
    params: { emailAddr, erc20Addr }
  });
}
async function withdrawRebate(account, claimContent, v, r, s) {
  const data = await ICommissionAwardContract(
    env.AWARDAddr,
    ICommissionAward,
    await getSigner()
  ).awardClaim(account, claimContent, v, r, s);
  console.log("data", data);
  await data.wait();
  return data;
}
async function getBlockchainTime() {
  const time1 = new Date().getTime();
  const data = await IStakeContract(
    env.STAKEAddr,
    IStake,
    await getSigner()
  ).getBlockchainTime();
  const time2 = new Date().getTime();
  const delay = time2 - time1;
  const res = BigNumber.from(data).toNumber() * 1e3 - delay;
  return res;
}
async function getStakePool(poolId) {
  const addr = env.STAKEAddr;
  const data = await IStakeContract(addr, IStake, await getSigner()).queryPoolInfo(
    poolId
  );
  const stake = await IStakeContract(addr, IStake, await getSigner()).queryUserStake(
    poolId
  );
  const {
    poolId: _poolId,
    beginVaultTime,
    claimedNftNumber,
    endStakingTime,
    endVaultTime,
    rewardNftAllocNumber,
    rewardNftMaxSupply,
    rewardNftType,
    stakingAddrNumber,
    stakingTokenAddr,
    status,
    takerThreshold,
    totalStakingTokens
  } = data;
  const { nftNumber, stakingNumber } = stake;
  const pool = {
    poolId: _poolId,
    beginVaultTime,
    claimedNftNumber,
    endStakingTime,
    endVaultTime,
    rewardNftAllocNumber,
    rewardNftMaxSupply,
    rewardNftType,
    stakingAddrNumber,
    stakingTokenAddr,
    status,
    takerThreshold,
    totalStakingTokens,
    nftNumber,
    stakingNumber
  };
  Object.keys(pool).forEach((key) => {
    if (key === "takerThreshold" || key === "totalStakingTokens" || key === "stakingNumber") {
      pool[key] = utils$2.formatEther(pool[key]);
    } else if (pool[key]._isBigNumber) {
      pool[key] = pool[key].toString();
    }
  });
  return pool;
}
const formatDateUTC = (timestamp) => {
  if (timestamp) {
    timestamp = Number(timestamp);
    const offset = new Date().getTimezoneOffset() * 6e4;
    const date = new Date(timestamp * 1e3 + offset).toLocaleString();
    return `UTC ${date}`;
  } else {
    return "-";
  }
};
function formatNumberWithDot(n) {
  !n && (n = "0");
  return Number(n).toLocaleString();
}
async function queryUserStake(poolId) {
  const data = await IStakeContract(
    env.STAKEAddr,
    IStake,
    await getSigner()
  ).queryUserStake(poolId);
  return data;
}
async function depositStake(poolId, amount) {
  const data = await IStakeContract(
    env.STAKEAddr,
    IStake,
    await getSigner()
  ).deposit(poolId, utils$2.parseEther(amount));
  const res = await data.wait();
  GoogleAnalyticsPurchase(amount, "FIRE", poolId, [
    {
      item_name: `\u8D28\u62BC${poolId}`,
      item_id: poolId,
      affiliation: "marketplace"
    }
  ]);
  return res;
}
async function withdrawAndClaim(poolId) {
  const data = await IStakeContract(
    env.STAKEAddr,
    IStake,
    await getSigner()
  ).withdrawAndClaim(poolId);
  const res = await data.wait();
  return res;
}

const loyalty$1 = {
  __proto__: null,
  getInviteCodeByEmail: getInviteCodeByEmail,
  getRebateInfo: getRebateInfo,
  getRebateSign: getRebateSign,
  withdrawRebate: withdrawRebate,
  getBlockchainTime: getBlockchainTime,
  getStakePool: getStakePool,
  formatDateUTC: formatDateUTC,
  formatNumberWithDot: formatNumberWithDot,
  queryUserStake: queryUserStake,
  depositStake: depositStake,
  withdrawAndClaim: withdrawAndClaim
};

async function generateWalletAddress() {
  return await serviceNodeJs.get(`/sso/createAddress`);
}

const managed$1 = {
  __proto__: null,
  generateWalletAddress: generateWalletAddress
};

const REACT_APP_ERC1155_ETHNFT = "0xc9c79a1767ff2ac76d9c09a06627470653010dd7";
const REACT_APP_FIREREDEEN = "0x9b71087cbceb5d0a6c2685d0f40f3e586b9a7d02";
async function testIsApprovedForAll() {
  const account = await getWalletAddress();
  const data = await IERC721Contract(
    REACT_APP_ERC1155_ETHNFT,
    IERC721$1,
    await getSigner()
  ).isApprovedForAll(account, REACT_APP_FIREREDEEN);
  console.log("testIsApprovedForAll", data);
  return data;
}
async function testSetApprovalForAll() {
  const data = await IERC721Contract(
    REACT_APP_ERC1155_ETHNFT,
    IERC721$1,
    await getSigner()
  ).setApprovalForAll(REACT_APP_FIREREDEEN, true);
  await data.wait().then(console.log);
  console.log("testSetApprovalForAll", data);
  return data;
}

const test = {
  __proto__: null,
  testIsApprovedForAll: testIsApprovedForAll,
  testSetApprovalForAll: testSetApprovalForAll
};

async function fetchList(query = {}, listType = "topNft") {
  if (listType === "active") {
    return serviceNodeJs$1.get("/api/v1/statistics/activity", {
      params: query
    });
  }
  return serviceNodeJs$1.get("/api/v1/statistics/topNft", {
    params: query
  });
}

const statistics = {
  __proto__: null,
  fetchList: fetchList
};

async function getOrderDetail(sessionId) {
  return await serviceNodeJs.post(`/store/getOrderDetail`, { sessionId });
}

const stripe = {
  __proto__: null,
  getOrderDetail: getOrderDetail
};

const index$1 = {
  __proto__: null,
  requestJava: serviceNodeJs$1,
  requestNodeJs: serviceNodeJs,
  IERC721: IERC721,
  lootbox: lootbox$1,
  storage: storage$1,
  utils: utils$1,
  user: user,
  loyalty: loyalty$1,
  ERC20: ERC20,
  managed: managed$1,
  test: test,
  statistics: statistics,
  stripe: stripe
};

function calcCardAttrs(obj) {
  const { Endurance, Agility, Pisonic, Strength, Crit, Spirit, Armor } = obj.attrs;
  obj.attrs.Total = Endurance + Agility + Pisonic + Strength + Crit + Spirit + Armor;
  return obj;
}

async function openLootbox(typeId, qty, detail) {
  const openerAddr = await queryOpenerAddr(typeId);
  const isApproved = await lootboxIsApprovedForAll(openerAddr);
  if (!isApproved) {
    await lootboxSetApprovalForAll(openerAddr);
  }
  const data = await batchOpen(openerAddr, detail.list.splice(0, qty));
  const { blockNumber } = data;
  console.log(`\u76F2\u76D2\u6253\u5F00\u6210\u529F\uFF0C\u533A\u5757\u9AD8\u5EA6\uFF1A${blockNumber}`);
  return data;
}
async function checkOpenRes(typeId, blockNumber) {
  const goodIds = isShip(typeId) ? await checkOpenShip(blockNumber) : await checkOpen(blockNumber);
  console.log("goodIds", goodIds);
  const goodInfos = [];
  for (const item of goodIds) {
    const url = isShip(typeId) ? await getTokenURIByTokenId(item, env.StarShipAddr) : await getTokenURIByTokenId(item);
    const res = await getInfoByTokenURI(url);
    const info = calcCardAttrs(res);
    console.log("info", info);
    goodInfos.push(info);
    console.log("goodInfos", goodInfos);
  }
  return goodInfos;
}
function isShip(typeId) {
  return typeId === 17;
}

const lootbox = {
  __proto__: null,
  openLootbox: openLootbox,
  checkOpenRes: checkOpenRes,
  isShip: isShip
};

function add(a, b) {
  return a + b;
}
function mul(a, b) {
  return a * b;
}
const aspectFit = (imageWidth, imageHeight, canvasWidth, canvasHeight) => {
  const imageRate = imageWidth / imageHeight;
  const canvasRate = canvasWidth / canvasHeight;
  let [dx, dy, dw, dh] = [0, 0, 0, 0];
  if (imageRate >= canvasRate) {
    dw = canvasWidth;
    dh = canvasWidth / imageRate;
  } else {
    dh = canvasHeight;
    dw = canvasHeight * imageRate;
  }
  dx = (canvasWidth - dw) / 2;
  dy = (canvasHeight - dh) / 2;
  return [dx, dy, dw, dh];
};
function aspectFill(imageWidth, imageHeight, canvasWidth, canvasHeight) {
  const imageRate = imageWidth / imageHeight;
  const canvasRate = canvasWidth / canvasHeight;
  let [sx, sy, sw, sh] = [0, 0, 0, 0];
  if (imageRate >= canvasRate) {
    sw = imageHeight * canvasRate;
    sh = imageHeight;
    sx = (imageWidth - sw) / 2;
    sy = 0;
  } else {
    sh = imageWidth / canvasRate;
    sw = imageWidth;
    sx = 0;
    sy = (imageHeight - sh) / 2;
  }
  return [sx, sy, sw, sh];
}
async function accountIsSameAsCurrentWallet() {
  console.log("accountIsSameAsCurrentWallet");
  try {
    const account = localStorage.getItem("account") || localStorage.getItem("Address");
    console.log("account", account);
    const res = await getWalletEnvironment().request({ method: "eth_requestAccounts" });
    console.log("res", res);
    return res[0] === account;
  } catch (error) {
    return false;
  }
}

const utils = {
  __proto__: null,
  add: add,
  mul: mul,
  aspectFit: aspectFit,
  aspectFill: aspectFill,
  accountIsSameAsCurrentWallet: accountIsSameAsCurrentWallet
};

function generateInviteLink(code) {
  const link = `${env.ssoUrl}/login?act=reg&code=${code}`;
  return link;
}

const loyalty = {
  __proto__: null,
  generateInviteLink: generateInviteLink
};

async function getRequestMethod() {
  const showLog = process.env.FIREWORK_ENV !== "production";
  showLog && console.log("isLogin", isLogin());
  if (isLogin()) {
    showLog && console.log("isBindWallet", await isBindWallet());
    if (await isBindWallet()) {
      showLog && console.log("isManagedAccount", await isManagedAccount());
      if (await isManagedAccount()) {
        return ["web", "ok"];
      } else {
        showLog && console.log("isInstallWalletPlugin", isInstallWalletPlugin());
        if (isInstallWalletPlugin()) {
          showLog && console.log("isWalletAddressMatch", await isWalletAddressMatch());
          if (await isWalletAddressMatch()) {
            showLog && console.log("isCorrectChainId", await isCorrectChainId());
            if (await isCorrectChainId()) {
              return ["blockchain", "ok"];
            } else {
              return ["web", "Wrong chain id"];
            }
          } else {
            return ["blockchain", "Wallet address is inconsistent"];
          }
        } else {
          return ["web", "Please install metamask"];
        }
      }
    } else {
      showLog && console.log("isInstallWalletPlugin", isInstallWalletPlugin());
      if (isInstallWalletPlugin()) {
        showLog && console.log("isCorrectChainId", await isCorrectChainId());
        if (await isCorrectChainId()) {
          return ["blockchain", "ok"];
        } else {
          return ["web", "Wrong chain id"];
        }
      } else {
        return ["web", "Please bind wallet"];
      }
    }
  } else {
    showLog && console.log("isInstallWalletPlugin", isInstallWalletPlugin());
    if (isInstallWalletPlugin()) {
      showLog && console.log("isCorrectChainId", await isCorrectChainId());
      if (await isCorrectChainId()) {
        return ["blockchain", "ok"];
      } else {
        return ["web", "Wrong chain id"];
      }
    } else {
      return ["web", "ok"];
    }
  }
}
function isLogin() {
  return Boolean(getToken());
}
async function isBindWallet() {
  const userInfo = await getUserInfoFast();
  return Boolean(userInfo ? userInfo.WalletAddress : null);
}
async function isManagedAccount() {
  const userInfo = await getUserInfoFast();
  return Boolean(userInfo?.BindAddrType === 2);
}
function isInstallWalletPlugin() {
  if (getWalletEnvironment()) {
    return true;
  }
  return false;
}
async function isWalletAddressMatch() {
  const userInfo = await getUserInfoFast();
  const [account] = await getWalletEnvironment().request({ method: "eth_accounts" });
  return Boolean(account && userInfo?.WalletAddress === account);
}
function getUserInfoFromSessionStorage() {
  try {
    const token = sessionStorage.getItem("token");
    if (!token || token !== getToken()) {
      return null;
    }
    const userInfo = sessionStorage.getItem("userInfo");
    return userInfo ? JSON.parse(userInfo) : null;
  } catch (error) {
    return null;
  }
}
async function getUserInfoFast() {
  const userInfo = getUserInfoFromSessionStorage();
  if (userInfo?.UserName || userInfo?.UserWithThirdName) {
    return userInfo;
  } else {
    const userInfoRes = await getUserInfo();
    if (userInfoRes.code === 200 && userInfoRes.data) {
      sessionStorage.setItem("userInfo", JSON.stringify(userInfoRes.data));
      sessionStorage.setItem("token", getToken());
      return userInfoRes.data;
    } else {
      return null;
    }
  }
}
async function isCorrectChainId() {
  const chainId = await getWalletEnvironment().request({ method: "eth_chainId" });
  return chainId === env.CHAIN_ID;
}

const managed = {
  __proto__: null,
  getRequestMethod: getRequestMethod,
  getUserInfoFast: getUserInfoFast
};

async function handleSell(tokenId, nftType, price) {
  const [account] = await getWalletEnvironment().request({ method: "eth_requestAccounts" });
  const isApproved = await isApprovedForAll(
    env.BlindBoxAddr,
    env.NFTTransferAddr
  );
  console.log("isApproved", isApproved);
  if (!isApproved) {
    const data = await setApprovalForAll(env.BlindBoxAddr, env.NFTTransferAddr);
    console.log("setApprovalForAll", data);
  }
  const msgStr = `tokenId=${tokenId}&address=${account}`;
  let signature = "";
  if (getWalletEnvironment()) {
    const jsonRpcProvider = new ethers.providers.Web3Provider(getWalletEnvironment());
    const signer = jsonRpcProvider.getSigner();
    signature = await signer.signMessage(msgStr);
  }
  const postData = {
    tokenId,
    nftType,
    ownerAddress: account,
    address: account,
    price,
    signature
  };
  return await serviceNodeJs$1.post("/api/v1/informationCenter/sellToken", postData);
}
async function handleCancel(tokenId, nftType) {
  const [account] = await getWalletEnvironment().request({ method: "eth_requestAccounts" });
  const msgStr = `tokenId=${tokenId}&address=${account}`;
  let signature = "";
  if (getWalletEnvironment()) {
    const jsonRpcProvider = new ethers.providers.Web3Provider(getWalletEnvironment());
    const signer = jsonRpcProvider.getSigner();
    signature = await signer.signMessage(msgStr);
  }
  const postData = {
    address: account,
    tokenId,
    nftType,
    signature
  };
  return await serviceNodeJs$1.post("/api/v1/informationCenter/cancelSell", postData);
}
async function handleBuy(tokenId, nftType, bidPrice) {
  const balanceFire = await getUsdtTokenBalance();
  if (Number(balanceFire) < Number(bidPrice))
    throw new Error("Transaction failed. You do not have enough balance.");
  const allowanceUsdt = await getERC20Allowance(
    env.TOKEN_ERC20_USDT,
    env.NFTTransferAddr
  );
  if (Number(allowanceUsdt) < Number(bidPrice)) {
    console.log("Approve Usdt");
    console.log("bidPrice", bidPrice);
    console.log("utils.parseEther(bidPrice)", utils$2.parseEther(bidPrice));
    await setERC20Allowance(
      env.TOKEN_ERC20_USDT,
      env.NFTTransferAddr,
      utils$2.parseEther(bidPrice)
    );
  }
  const erc721Address = getErc721Address(nftType);
  if (!erc721Address)
    throw new Error("Invalid erc721 contract address.");
  const allowanceERC721 = await isApprovedForAll(erc721Address, env.NFTTransferAddr);
  if (!allowanceERC721) {
    console.log("Approve erc721");
    await setApprovalForAll(erc721Address, env.NFTTransferAddr);
  }
  const [account] = await getWalletEnvironment().request({ method: "eth_requestAccounts" });
  const msgStr = `tokenId=${tokenId}&address=${account}`;
  let signature = "";
  if (getWalletEnvironment()) {
    const jsonRpcProvider = new ethers.providers.Web3Provider(getWalletEnvironment());
    const signer = jsonRpcProvider.getSigner();
    signature = await signer.signMessage(msgStr);
  }
  const postData = {
    address: account,
    tokenId,
    nftType,
    price: bidPrice,
    signature
  };
  return await serviceNodeJs$1.post("/api/v1/informationCenter/buyToken", postData);
}
function getErc721Address(nftType) {
  switch (Number(nftType)) {
    case cardType.card:
      return env.FireworkCardAddr;
    case cardType.spaceship:
      return env.StarShipAddr;
    case cardType.lootbox:
      return env.BlindBoxAddr;
    default:
      return "";
  }
}
async function handleBeam(tokenId, nftType, toAccount, tokenQty) {
  const erc721Address = getErc721Address(nftType);
  if (!erc721Address)
    throw new Error("Invalid erc721 contract address.");
  const beamTransfer = Number(nftType) === cardType.material ? env.NFTTransfer1155Addr : env.NFTTransferAddr;
  const allowanceERC721 = await isApprovedForAll(erc721Address, beamTransfer);
  if (!allowanceERC721) {
    console.log("Approve erc721");
    await setApprovalForAll(erc721Address, beamTransfer);
  }
  const [account] = await getWalletEnvironment().request({ method: "eth_requestAccounts" });
  if (account.toLowerCase() === ethers.utils.getAddress(toAccount).toLowerCase()) {
    throw new Error("The address cannot be the same as the address of the sender");
  }
  const contract = NFTTransferContract(beamTransfer, null, await getSigner());
  let data;
  if (Number(nftType) === cardType.material) {
    data = await contract.sendItem1155(
      erc721Address,
      account,
      ethers.utils.getAddress(toAccount),
      [tokenId],
      [tokenQty]
    );
  } else {
    data = await contract.sendNft(erc721Address, tokenId, ethers.utils.getAddress(toAccount));
  }
  await data.wait();
  return data;
}
async function handleFav(tokenId, nftType, addFavorite = true) {
  const [account] = await getWalletEnvironment().request({ method: "eth_requestAccounts" });
  let api;
  if (!addFavorite) {
    api = `/api/v1/informationCenter/favorite/add?address=${account}&tokenId=${tokenId}&nftType=${nftType}`;
  } else {
    api = `/api/v1/informationCenter/favorite/remove?address=${account}&tokenId=${tokenId}&nftType=${nftType}`;
  }
  return await serviceNodeJs$1.post(api);
}
async function handleAddViews(tokenId, nftType, ownerAddress) {
  if (ownerAddress && Number(nftType) === cardType.material) {
    return await serviceNodeJs$1.post(
      `/api/v1/marketplace/token/views/add?tokenId=${tokenId}&nftType=${nftType}&ownerAddress=${ownerAddress}`
    );
  } else {
    return await serviceNodeJs$1.post(
      `/api/v1/marketplace/token/views/add?tokenId=${tokenId}&nftType=${nftType}`
    );
  }
}

const storage = {
  __proto__: null,
  handleSell: handleSell,
  handleCancel: handleCancel,
  handleBuy: handleBuy,
  handleBeam: handleBeam,
  handleFav: handleFav,
  handleAddViews: handleAddViews
};

const index = {
  __proto__: null,
  lootbox: lootbox,
  utils: utils,
  loyalty: loyalty,
  managed: managed,
  storage: storage
};

export { index as BLL, index$1 as DAL, index$2 as Data };
